main {
    min-height: 80vh;
  }
  
  .rating span {
    margin: 0.1rem;
  }
  
  .rating svg {
    color: #f8e825;
  }
  
  .rating-text {
    font-size: 0.8rem;
    font-weight: 600;
    padding-left: 0.5rem;
  }
  
  .link-no {
    color: inherit;
    text-decoration: none;
  }
  
  table td,
  table th {
    text-align: center;
  }
  
  .review {
    margin-top: 30px;
  }
  
  .review h2 {
    font-size: 24px;
    background: #f4f4f4;
    padding: 10px;
    border: 1px solid #ddd;
  }
  
  .review button {
    margin-top: 10px;
  }

  header img {
    width: 15%;
    height: 15%;
  }

  .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #6b717b;
    --bs-btn-border-color: #6b717b;
    --bs-btn-hover-bg: #b1b3b6;
    --bs-btn-hover-border-color: #6b717b;
    --bs-btn-active-border-color: #6b717b;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);

    --bs-btn-disabled-bg: #c8c9cc;
    --bs-btn-disabled-border-color: #c8c9cc;
     /* --bs-btn-hover-color: #fff;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #6b717b;
    --bs-btn-disabled-color: #fff;
     */
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #a6aaad;
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #6c757d;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: #6c757d;
  border-color: var(--bs-pagination-active-border-color);
}

.carousel-caption {
  position: absolute;
  width: inherit;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  color: inherit;
}





@media screen and (min-width: 48em) {
  .card-img-top {
    width: 100%;
    height: 15vw;
    object-fit: cover;
  }

  /* .carousel {
    width: 480px;
  } */
  
  .carousel-image {
    /* width:480px; */
    height:380px;
  }
}

/* shadow box only image */
/* .img-container {
  position: relative;
  overflow: hidden;
}

.img-container img {
  transition: transform 0.3s ease-in-out;
}

.img-container:hover img {
  transform: scale(1.1); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
} */

/* shadow box athe entire card */
.card-container {
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
}

.card-container:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9); /* Adjust the shadow properties as needed */
}

.card-container .text-container {
  padding: 1rem;
}

.upper-div {
  position: relative;
}


/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
}

.carousel-inner {
  background: whitesmoke;
}

.test-pay-button {
  margin-bottom: 10px;
  width: -webkit-fill-available;
}